import { useCallback } from 'react';
import useMilesAuth from '../useMilesAuth/useMilesAuth';

import {
  SetStatusParams,
  StatusResponse,
  TheresaSchedule,
  UseTheresa,
} from './useTheresa.model';

const useTheresa: UseTheresa = () => {
  const [{ loggedAxios }] = useMilesAuth();

  const getStatus = useCallback(
    async (): Promise<StatusResponse> => {
      try {
        const result = await loggedAxios().get<StatusResponse>('/api/theresa');

        if (result.status == 200) {
          return result.data;
        }
      } catch (error) {
        console.error('Unable to get status', error);
      }

      throw new Error('Unable to get status');
    },
    [],
  );

  const setStatus = useCallback(
    async (status: SetStatusParams) => {
      try {
        const result = await loggedAxios()
          .post<SetStatusParams, StatusResponse>(
            '/api/theresa',
            status,
          );

        if (result.status >= 200 && result.status < 400) {
          return true;
        }
      } catch (error) {
        console.error('Unable to set status', error);
      }

      return false;
    },
    [],
  );

  const addSchedule = useCallback(
    async (schedule: TheresaSchedule) => {
      try {
        const result = await loggedAxios()
          .post<TheresaSchedule, TheresaSchedule>(
            '/api/theresa/schedules',
            schedule,
          );

        if (result.status >= 200 && result.status < 400) {
          return true;
        }
      } catch (error) {
        console.error('Unable to add theresa schedule', error);
      }

      return false;
    },
    [],
  );

  const updateSchedule = useCallback(
    async (schedule: TheresaSchedule) => {
      try {
        const result = await loggedAxios()
          .put<TheresaSchedule, TheresaSchedule>(
            `/api/theresa/schedules/${schedule.id}`,
            schedule,
          );

        if (result.status >= 200 && result.status < 400) {
          return true;
        }
      } catch (error) {
        console.error('Unable to update theresa schedule', error);
      }

      return false;
    },
    [],
  );

  const removeSchedule = useCallback(
    async (id: number) => {
      try {
        const result = await loggedAxios()
          .delete<TheresaSchedule>(
            `/api/theresa/schedules/${id}`,
          );

        if (result.status >= 200 && result.status < 400) {
          return true;
        }
      } catch (error) {
        console.error('Unable to remove theresa schedule', error);
      }

      return false;
    },
    [],
  );

  return [{ getStatus, setStatus, addSchedule, updateSchedule, removeSchedule }];
};

export default useTheresa;
