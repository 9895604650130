// src/contexts/WebSocketContext.tsx
import React, { createContext, useEffect, useRef, useState } from 'react';
import { SetStatusParams, StatusResponse } from '../hooks/useTheresa/useTheresa.model';
import useTheresa from '../hooks/useTheresa/useTheresa';
import { TheresaWsContextType, TheresaWsProviderProps } from './TheresaContext.model';
import useMilesLocalStorage from '../hooks/useMilesLocalStorage/useMilesLocalStorage';

// Create the context with an initial value of null
export const TheresaWsContext = createContext<TheresaWsContextType | null>(null);

export const TheresaWsProvider: React.FC<TheresaWsProviderProps> = ({ children }) => {
    const [state, setState] = useState<StatusResponse>();
    const [localStorage] = useMilesLocalStorage();
    const [{ getStatus, setStatus, addSchedule, updateSchedule, removeSchedule }] = useTheresa();
    const eventSourceRef = useRef<ReadableStreamDefaultReader | null>(null);

    const fetchSSE = async () => {

        const status = await getStatus();
        setState(status);

        const response = await fetch(
            'api/theresa/sse',
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'text/event-stream',
                    'Authorization': `Bearer ${localStorage.jwt}`,
                },
            }
        );

        const reader = response.body?.pipeThrough(new TextDecoderStream()).getReader();

        if (!reader) {
            console.error('Failed to get reader from response body.');
            return;
        }

        while (true) {
            const { value, done } = await reader.read();
            if (done) break;
            if (value) {
                if (value.startsWith('data: ')) {
                    const status = await getStatus();
                    setState(status);
                }
            }
        }
    };

    useEffect(() => {
        try {
            fetchSSE();
        } catch (error) {
            console.log('Unable to fetch SSE', error);
        }

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                // Restart SSE connection when the user returns to the page
                if (eventSourceRef.current) {
                    eventSourceRef.current.cancel(); // Cancel any existing reader before restarting
                }
                try {
                    fetchSSE();
                } catch (error) {
                    console.log('Unable to fetch SSE', error);
                }
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            // Clean up event listener and SSE connection
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            if (eventSourceRef.current) {
                eventSourceRef.current.cancel();
            }
        };
    }, []);

    const sendMessage = async (message: SetStatusParams) => {
        await setStatus(message);
    };

    return (
        <TheresaWsContext.Provider value={{
            acState: state?.status,
            schedules: state?.schedules,
            sendMessage,
            addSchedule,
            updateSchedule,
            removeSchedule,
        }}>
            {children}
        </TheresaWsContext.Provider>
    );

}
