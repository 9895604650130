import { useCallback } from 'react';
import useMilesAuth from '../useMilesAuth/useMilesAuth';

import {
  TodoItem,
  TodoItemInput,
  UseTodoItems,
} from './useTodoItems.model';

const useTodoItems: UseTodoItems = () => {
  const [{ loggedAxios }] = useMilesAuth();

  const getItems = useCallback(
    async (): Promise<TodoItem[]> => {
      try {
        const result = await loggedAxios().get<TodoItem[]>('/api/todo-items');

        if (result.status == 200) {
          return result.data;
        }
      } catch (error) {
        console.error('Unable to get items', error);
      }

      throw new Error('Unable to get items');
    },
    [],
  );


  const resetItem = useCallback(
    async (id: number) => {
      try {
        const result = await loggedAxios()
          .post<string, void>(
            `/api/todo-items/reset/${id}`,
            ''
          );

        if (result.status == 200) {
          return true;
        }
      } catch (error) {
        console.error(`Unable to reset item ${id}`, error);
      }

      return false;
    },
    [],
  );

  const upsertItem = useCallback(
    async (item: TodoItemInput) => {
      try {
        const result = item.id
          ? await loggedAxios()
            .put<TodoItemInput, void>(
              `/api/todo-items/${item.id}`,
              item,
            )
          : await loggedAxios()
            .post<TodoItemInput, void>(
              '/api/todo-items',
              item,
            );

        if (result.status >= 200 && result.status < 400) {
          return true;
        }
      } catch (error) {
        console.error('Unable to upsert item!', error);
      }

      return false;
    },
    [],
  );

  const deleteItem = useCallback(
    async (id: number) => {
      try {
        const result = await loggedAxios()
          .delete<void>(
            `/api/todo-items/${id}`,
          );

        if (result.status >= 200 && result.status < 400) {
          return true;
        }
      } catch (error) {
        console.error('Unable to delete item!', error);
      }

      return false;
    },
    [],
  );

  return [{ getItems, resetItem, upsertItem, deleteItem }];
};

export default useTodoItems;
