import { useCallback } from 'react';
import useMilesAuth from '../useMilesAuth/useMilesAuth';

import {
  UseStats,
  HistoryPoint,
  RatioPoint,
  ThisMonthProgress,
  SplitHistoryResult,
} from './useStats.model';

const useStats: UseStats = () => {
  const [{ loggedAxios }] = useMilesAuth();

  const makeRatioRequestor = (typeId: number) => {
    return useCallback(
      async (): Promise<RatioPoint> => {
        try {
          const result = await loggedAxios().get<RatioPoint>(`/api/stats/proportion/by-type/${typeId}`);

          if (result.status == 200) {
            return result.data;
          }
        } catch (error) {
          console.error('Unable to get ratios', error);
        }

        throw new Error('Unable to get ratios');
      },
      [],
    );
  };

  const getTotalHistory = useCallback(
    async (): Promise<HistoryPoint[]> => {
      try {
        const result = await loggedAxios().get<HistoryPoint[]>('/api/stats/history/total/500');

        if (result.status == 200) {
          return result.data;
        }
      } catch (error) {
        console.error('Unable to get history', error);
      }

      throw new Error('Unable to get history');
    },
    [],
  );

  const getTotalTypeHistory = useCallback(
    async (): Promise<SplitHistoryResult> => {
      try {
        const result = await loggedAxios().get<SplitHistoryResult>('/api/stats/history/by-type/0/500');

        if (result.status == 200) {
          return result.data;
        }
      } catch (error) {
        console.error('Unable to get history', error);
      }

      throw new Error('Unable to get history');
    },
    [],
  );

  const getTotalAccountHistory = useCallback(
    async (): Promise<SplitHistoryResult> => {
      try {
        const result = await loggedAxios().get<SplitHistoryResult>('/api/stats/history/by-account/500');

        if (result.status == 200) {
          return result.data;
        }
      } catch (error) {
        console.error('Unable to get history', error);
      }

      throw new Error('Unable to get history');
    },
    [],
  );

  const getAccountRatios = useCallback(
    async (): Promise<RatioPoint> => {
      try {
        const result = await loggedAxios().get<RatioPoint>('/api/stats/proportion/by-account');

        if (result.status == 200) {
          return result.data;
        }
      } catch (error) {
        console.error('Unable to get ratios', error);
      }

      throw new Error('Unable to get ratios');
    },
    [],
  );

  const getThisMonth = useCallback(
    async (): Promise<ThisMonthProgress> => {
      try {
        const result = await loggedAxios().get<ThisMonthProgress>('/api/stats/proportion/this-month');

        if (result.status == 200) {
          return result.data;
        }
      } catch (error) {
        console.error('Unable to get this month', error);
      }

      throw new Error('Unable to get this month');
    },
    [],
  );

  return [{
    getTotalHistory,
    getThisMonth,
    getAccountRatios,
    getTotalTypeHistory,
    getTotalAccountHistory,
    getTypeRatios: makeRatioRequestor(0),
    getTypeRatioMeals: makeRatioRequestor(2),
    getTypeRatioHousing: makeRatioRequestor(3),
    getTypeRatioExtras: makeRatioRequestor(4),
  }];
};

export default useStats;
