import * as React from 'react';
import { TheresaWsContext } from '../../contexts/TheresaContext';
import Title from '../title/Title';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControlLabel, Grid, IconButton, MenuItem, Select, SelectChangeEvent, Slider, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { TheresaSchedule } from '../../hooks/useTheresa/useTheresa.model';
import { Add, Delete, Edit } from '@mui/icons-material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export default function ACSchedules() {
    const context = React.useContext(TheresaWsContext);
    const [selectedTime, setSelectedTime] = React.useState<Date | null>(new Date());

    const handleTimeChange = (newTime: Date | null) => {
        setSelectedTime(newTime);
        setFormProps({
            ...formProps,
            editElement: {
                ...formProps.editElement,
                scheduledTime: newTime?.toISOString() ?? formProps.editElement.scheduledTime
            }
        });
    };

    const handleRelativeTimeChange = (event: SelectChangeEvent<number>) => {
        const value = event.target.value as number;
        const newTime = Date.now() + value * 60 * 1000;
        handleTimeChange(new Date(newTime));
    };

    const [formProps, setFormProps] = React.useState<{
        open: boolean;
        isCreation: boolean;
        editElement: TheresaSchedule;
        isPosting: boolean;
    }>({
        open: false,
        isCreation: false,
        editElement: {
            id: undefined,
            scheduledTime: new Date().toISOString(),
            setActive: true,
            setTemperature: 21
        },
        isPosting: false,
    });

    if (!context) {
        return <div>Caricamento...</div>;
    }

    const tempMarks = [
        { value: 17, label: '17°C' },
        { value: 19, label: '19°C' },
        { value: 21, label: '21°C' },
        { value: 23, label: '23°C' },
        { value: 25, label: '25°C' },
    ]

    const { schedules, addSchedule, updateSchedule, removeSchedule } = context;

    const onSave = () => {
        if (formProps.isCreation) {
            addSchedule(formProps.editElement);
        } else {
            updateSchedule(formProps.editElement);
        }
        setFormProps({ ...formProps, open: false });
    };

    const onDelete = () => {
        if (!confirm('Eliminare?')) {
            return;
        }
        removeSchedule(formProps.editElement.id!);
        setFormProps({ ...formProps, open: false });
    };

    const openEditForm = (i: TheresaSchedule) => {
        setSelectedTime(new Date(i.scheduledTime));
        setFormProps({
            ...formProps,
            isCreation: false,
            editElement: i,
            open: true,
        });
    };

    return (<>
        <Paper
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                minHeight: '150px',
                minWidth: '300px',
            }}>
            <Title>Pianificazione</Title>
            {(schedules?.length)
                ? <List dense>
                    {schedules
                        .map((i) => {
                            const formattedTime = dayjs(i.scheduledTime)
                                .format('HH:mm A');
                            const timeFromNow = dayjs(i.scheduledTime)
                                .fromNow();

                            return <ListItem
                                secondaryAction={
                                    <IconButton onClick={() => openEditForm(i)} edge="end" aria-label="edit"><Edit /></IconButton>
                                }
                                divider>
                                <ListItemText
                                    primary={formattedTime + " (" + timeFromNow + ")"}
                                    secondary={[
                                        ...(i.setActive === null
                                            ? []
                                            : ["Set Stato: " + (i.setActive === true ? "Acceso" : "Spento")]),
                                        ...(i.setTemperature === null
                                            ? []
                                            : ["Set Temperatura: " + i.setTemperature + "°C"]),
                                    ].join(' - ')}
                                />
                            </ListItem>;
                        }
                        )}
                </List>
                : <p style={{ fontStyle: 'italic' }}>Nessuna pianificazione</p>}

            <Box display={"flex"} justifyContent={"flex-end"}>
                <Fab
                    onClick={() => {
                        setSelectedTime(new Date());
                        setFormProps({
                            ...formProps,
                            isCreation: true,
                            editElement: {
                                id: undefined,
                                scheduledTime: new Date().toISOString(),
                                setActive: true,
                                setTemperature: 21
                            },
                            open: true
                        });
                    }}
                    color="primary">
                    <Add />
                </Fab>
            </Box>
        </Paper>
        <Dialog open={formProps.open} onClose={() => { }}>
            <DialogTitle>{formProps.isCreation ? "Aggiungi" : "Modifica"} pianificazione</DialogTitle>
            <DialogContent>
                <Box p={2}>
                    <Grid container spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                value={selectedTime}
                                onChange={handleTimeChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        <Select
                            sx={{ marginLeft: '2em' }}
                            value={0}
                            onChange={handleRelativeTimeChange}
                            label="Incrementi"
                        >
                            <MenuItem value={30}>Tra 30 minuti</MenuItem>
                            <MenuItem value={60}>Tra 1 ora</MenuItem>
                            <MenuItem value={120}>Tra 2 ore</MenuItem>
                            <MenuItem value={240}>Tra 4 ore</MenuItem>
                        </Select>
                    </Grid>
                    <FormControlLabel
                        control={<Checkbox
                            checked={formProps.editElement?.setTemperature !== null}
                            onChange={(e, checked) => {
                                setFormProps({
                                    ...formProps,
                                    editElement: {
                                        ...formProps.editElement,
                                        setTemperature: checked ? 21 : null
                                    }
                                })
                            }}
                            name="setTemperature"
                            color="primary" />}
                        label="Modifica la temperatura" />
                    <Box mx={4}>
                        <Slider
                            aria-label="Temperatura"
                            defaultValue={21}
                            step={1}
                            valueLabelDisplay="auto"
                            min={17}
                            max={25}
                            marks={tempMarks}
                            disabled={formProps.editElement.setTemperature === null}
                            value={formProps.editElement?.setTemperature ?? 21}
                            onChangeCommitted={(e, value) => {
                                setFormProps({
                                    ...formProps,
                                    editElement: {
                                        ...formProps.editElement,
                                        setTemperature: value as number
                                    }
                                })
                            }}
                        />
                    </Box>
                    <FormControlLabel
                        control={<Checkbox
                            checked={formProps.editElement?.setActive !== null}
                            onChange={(e, checked) => {
                                setFormProps({
                                    ...formProps,
                                    editElement: {
                                        ...formProps.editElement,
                                        setActive: checked ? true : null
                                    }
                                })
                            }}
                            name="setActive"
                            color="primary" />}
                        label="Modifica lo stato" />
                    <Box>
                        <ToggleButtonGroup
                            value={formProps.editElement?.setActive === true ? 'on' : (formProps.editElement?.setActive === false ? 'off' : null)}
                            exclusive
                            disabled={formProps.editElement.setActive === null}
                            onChange={(e, nval) => setFormProps({
                                ...formProps,
                                editElement: {
                                    ...formProps.editElement,
                                    setActive: nval == 'on',
                                }
                            })}
                            color="primary">
                            <ToggleButton value="off">Off</ToggleButton>
                            <ToggleButton value="on">On</ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                {formProps.isCreation
                    ? <></>
                    : <LoadingButton loading={formProps.isPosting} color="error" onClick={onDelete}><Delete /></LoadingButton>}
                <div style={{ flex: '1 0 0' }} />
                <Button onClick={() => setFormProps({ ...formProps, open: false })}>Annulla</Button>
                <LoadingButton
                    disabled={
                        formProps.editElement.scheduledTime <= new Date().toISOString() ||
                        (formProps.editElement.setTemperature === null
                            && formProps.editElement.setActive === null)}
                    loading={formProps.isPosting}
                    onClick={onSave}>{formProps.isCreation ? "Aggiungi" : "Modifica"}</LoadingButton>
            </DialogActions>
        </Dialog>
    </>);
}