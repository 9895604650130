import React from 'react';

import { BrowserRouter, Navigate, useRoutes } from 'react-router-dom';

import MainContainer from '../../containers/main/MainContainer';
import useMilesAuth from '../../hooks/useMilesAuth/useMilesAuth';
import SignIn from '../signin/SignIn';
import SignOut from '../signout/SignOut';
import Dashboard from '../dashboard/Dashboard';
import Statistics from '../statistics/Statistics';
import Theresa from '../theresa/Theresa';
import TodoItems from '../todoitems/TodoItems';
import Expenses from '../expenses/Expenses';
import SetPassword from '../setpassword/SetPassword';
import ExpenseSearch from '../expenseSearch/ExpenseSearch';
import RecurringExpenses from '../recurringExpenses/RecurringExpenses';


const NotAuthenticatedRoutes: React.FC = () =>
    useRoutes([
        {
            path: '/login',
            element: <SignIn />,
        },
        {
            path: '*',
            element: <Navigate to="/login" />,
        },
    ]);

const AuthenticatedRoutes: React.FC = () => {
    const routes = useRoutes([
        {
            element: <MainContainer />,
            children: [
                { path: '/dashboard', element: <Dashboard /> },
                { path: '/expenses', element: <Expenses /> },
                { path: '/recurring-expenses', element: <RecurringExpenses /> },
                { path: '/expenses/:query', element: <ExpenseSearch /> },
                { path: '/todo', element: <TodoItems /> },
                { path: '/theresa', element: <Theresa /> },
                { path: '/statistics', element: <Statistics /> },
                { path: '/set-password', element: <SetPassword /> },
            ]
        },
        {
            path: '/logout',
            element: <SignOut />,
        },
        {
            path: '*',
            element: <Navigate to="/dashboard" />,
        },
    ]);

    return routes;
};

const Router: React.FC = () => {

    const [{ isLoggedIn, refresh }] = useMilesAuth();

    React.useEffect(() => {
        if (isLoggedIn()) {
            console.log('Trying token refresh...');
            refresh();
        }
    }, [isLoggedIn()]);

    return (
        <BrowserRouter basename="/">
            {isLoggedIn() ? <AuthenticatedRoutes /> : <NotAuthenticatedRoutes />}
        </BrowserRouter>
    );
};

export default Router;
