import * as React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TodoFormProps } from './TodoForm.model';
import {
    Button,
    Stack,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    TextField,
} from '@mui/material';

import { Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

export default function TodoForm({ onCancel, onSave, onDelete, onEditElement, state: formProps }: TodoFormProps) {
    return (<Dialog open={formProps.open} onClose={() => { }}>
        <DialogTitle>{formProps.isCreation ? "Aggiungi Da Fare" : "Modifica Elemento"}</DialogTitle>
        <DialogContent>
            <Stack spacing={3}>
                <TextField
                    margin="dense"
                    id="notes"
                    label="Nome"
                    type="text"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={formProps.editElement?.name}
                    onChange={(nv) => {
                        onEditElement({
                            ...formProps.editElement,
                            name: nv.target.value,
                        });
                    }}
                />
                <TextField
                    margin="dense"
                    id="amount"
                    label="Durata Nominale (giorni)"
                    type="number"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={formProps.editElement?.nominalExpiryDays}
                    onChange={(nv) => {
                        onEditElement({
                            ...formProps.editElement,
                            nominalExpiryDays: Number.parseInt(nv.target.value),
                        });
                    }}
                />
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Ultima esecuzione"
                            value={formProps.editElement?.lastResetTimestamp}
                            onChange={(nv) => {
                                if (nv) {
                                    try {
                                        onEditElement({
                                            ...formProps.editElement,
                                            lastResetTimestamp: new Date(nv)
                                                .toISOString()
                                                .substring(0, 10),
                                        });
                                    } catch {
                                        //ignored
                                    }
                                }
                            }}
                            renderInput={(params) => <TextField
                                sx={{ mt: 1 }}
                                size="small" {...params} />}
                        />
                    </LocalizationProvider>
                </FormControl>
            </Stack>
        </DialogContent>
        <DialogActions>
            {formProps.isCreation
                ? <></>
                : <LoadingButton loading={formProps.isPosting} color="error" onClick={onDelete}><Delete /></LoadingButton>}
            <div style={{ flex: '1 0 0' }} />
            <Button onClick={onCancel}>Annulla</Button>
            <LoadingButton
                loading={formProps.isPosting}
                onClick={onSave}>{formProps.isCreation ? "Aggiungi" : "Modifica"}</LoadingButton>
        </DialogActions>
    </Dialog>);
}