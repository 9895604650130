import { useCallback } from 'react';
import useMilesAuth from '../useMilesAuth/useMilesAuth';

import {
  RecurringExpense,
  RecurringExpenseInput,
  UseRecurringExpenses
} from './useRecurringExpenses.model';

const useRecurringExpenses: UseRecurringExpenses = () => {
  const [{ loggedAxios }] = useMilesAuth();

  const getItems = useCallback(
    async (): Promise<RecurringExpense[]> => {
      try {
        const result = await loggedAxios().get<RecurringExpense[]>('/api/recurring-expenses');

        if (result.status == 200) {
          return result.data;
        }
      } catch (error) {
        console.error('Unable to get items', error);
      }

      throw new Error('Unable to get items');
    },
    [],
  );

  const upsertItem = useCallback(
    async (item: RecurringExpenseInput) => {
      try {
        const result = item.id
          ? await loggedAxios()
            .put<RecurringExpenseInput, void>(
              `/api/recurring-expenses/${item.id}`,
              item,
            )
          : await loggedAxios()
            .post<RecurringExpenseInput, void>(
              '/api/recurring-expenses',
              item,
            );

        if (result.status >= 200 && result.status < 400) {
          return true;
        }
      } catch (error) {
        console.error('Unable to upsert item!', error);
      }

      return false;
    },
    [],
  );

  const deleteItem = useCallback(
    async (id: number) => {
      try {
        const result = await loggedAxios()
          .delete<void>(
            `/api/recurring-expenses/${id}`,
          );

        if (result.status >= 200 && result.status < 400) {
          return true;
        }
      } catch (error) {
        console.error('Unable to delete item!', error);
      }

      return false;
    },
    [],
  );

  return [{ getItems, upsertItem, deleteItem }];
};

export default useRecurringExpenses;
