import { Grid } from '@mui/material';
import * as React from 'react';
import { TheresaWsProvider } from '../../contexts/TheresaContext';
import ACControl from './ACControl';
import ACSchedules from './ACSchedules';

export default function Theresa() {

    return (
        <TheresaWsProvider>
            <Grid container spacing={3} justifyContent={'center'}>
                <Grid item xs={12} md={6}>
                    <ACControl />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ACSchedules />
                </Grid>
            </Grid>
        </TheresaWsProvider>
    );
}