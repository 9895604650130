import { Button, Grid, Paper, TextField, Snackbar, Alert } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import useMilesAuth from '../../hooks/useMilesAuth/useMilesAuth';
import useMilesLocalStorage from '../../hooks/useMilesLocalStorage/useMilesLocalStorage';
import Title from '../title/Title';

export default function SetPassword() {
    const [{ changePw }] = useMilesAuth();
    const [localStorageState] = useMilesLocalStorage();
    const [alertStatus, setAlertStatus] = React.useState({ open: false, success: false });

    const closeAlert = () => setAlertStatus({
        success: false,
        open: false
    });

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const { newPw, confPw } = {
            newPw: data.get('newPassword')?.toString(),
            confPw: data.get('confirmPassword')?.toString()
        };
        if (!newPw || newPw !== confPw) {
            alert('Le password non corrispondono!');
            return;
        }

        if (await changePw({
            username: localStorageState.username,
            password: newPw,
        })) {
            setAlertStatus({
                open: true,
                success: true
            })
        } else {
            setAlertStatus({
                open: true,
                success: false
            })
        }
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                    <Title>Cambio password</Title>
                    <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="newPassword"
                            label="Nuova Password"
                            type="password"
                            id="newPassword"
                            autoComplete="new-password"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Conferma Nuova Password"
                            type="password"
                            id="confirmPassword"
                            autoComplete="confirm-password"
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                type="submit"
                                variant="contained">OK</Button>
                        </Box>
                    </Box>
                </Paper>
                <Snackbar
                    open={alertStatus.open}
                    autoHideDuration={6000}
                    onClose={closeAlert}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert variant="filled"
                        elevation={6}
                        severity={alertStatus.success ? 'success' : 'warning'}
                        sx={{ width: '100%' }}>
                        {alertStatus.success ? 'Password aggiornata!' : 'Credenziali non valide!'}</Alert>
                </Snackbar>
            </Grid>
        </Grid>
    );
}