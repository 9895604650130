import * as React from 'react';
import { Divider, Typography } from '@mui/material';

interface TitleProps {
    children?: React.ReactNode;
}

export default function Title(props: TitleProps) {
    return (
        <>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                {props.children}
            </Typography>
            <Divider sx={{ mb: 2 }} />
        </>
    );
}