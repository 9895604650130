import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import NumberFormat from 'react-number-format';

interface ExpenseElementProps {
    readonly title?: string;
    readonly avg?: number;
    readonly cur?: number | null;
}

export default function ExpenseElement({ title, avg, cur }: ExpenseElementProps) {
    return (
        <Grid item xs sx={{ padding: '0.75rem', textAlign: 'center' }}>
            <Typography component="div" variant="h6" sx={{ textAlign: 'center' }}>{title ?? '-'}</Typography>
            <p>Corrente:<br /><NumberFormat value={cur?.toFixed(2)}
                displayType={'text'}
                thousandSeparator={true}
                defaultValue={'-'}
                prefix={'€'} />
            </p>
            <p>Media:<br /><NumberFormat value={avg?.toFixed(2)}
                displayType={'text'}
                thousandSeparator={true}
                defaultValue={'-'}
                prefix={'€'} />
            </p>
        </Grid>);
}