import Title from '../title/Title';
import {
    Grid,
    Paper,
    Badge,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    IconButton,
    Snackbar,
    Alert,
    Fab,
} from '@mui/material';

import {
    EventRepeat,
    Add
} from '@mui/icons-material';
import * as React from 'react';
import useTodoItems from '../../hooks/useTodoItems/useTodoItems';
import { TodoItem } from '../../hooks/useTodoItems/useTodoItems.model';
import { format } from 'date-fns';
import { IDialogState } from './forms/TodoForm.model';
import TodoForm from './forms/TodoForm';

export default function TodoItems() {
    const [{ getItems, resetItem, upsertItem, deleteItem }] = useTodoItems();
    const [items, setItems] = React.useState<TodoItem[]>([]);
    const [alertStatus, setAlertStatus] = React.useState({ open: false, success: false });
    const [openForm, setOpenForm] = React.useState<IDialogState>({ open: false, isCreation: false, editElement: { id: 0 }, isPosting: false });

    const closeAlert = () => setAlertStatus({
        open: false,
        success: alertStatus.success,
    });

    const loadItems = async () => {
        const i = await getItems();
        setItems(i);
    };

    React.useEffect(() => {
        loadItems();
    }, []);

    const handleReset = async (item: TodoItem) => {
        if (confirm(`Reset ${item.name}?`)) {
            const okay = await resetItem(item.id);
            setAlertStatus({
                open: true,
                success: okay
            });
            loadItems();
        }
    };

    const handleEdit = async (item: TodoItem) => {
        setOpenForm({
            open: true,
            isCreation: false,
            editElement: {
                ...item,
            },
            isPosting: false,
        });
    };

    const openAddForm = () => {
        const now = new Date();
        const today = new Date(now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            12,
            0,
            0);
        setOpenForm({
            open: true,
            isCreation: true,
            editElement: {
                id: 0,
                lastResetBy: {
                    id: 0
                },
                lastResetTimestamp: today.toISOString()
            },
            isPosting: false,
        });
    };

    const closeForm = () => {
        setOpenForm({
            ...openForm,
            open: false,
        });
    };

    const saveForm = async () => {
        setOpenForm({
            ...openForm,
            isPosting: true,
        });
        const result = await upsertItem(openForm.editElement);
        setAlertStatus({
            open: true,
            success: result,
        });
        setOpenForm({
            ...openForm,
            open: false,
            isPosting: false,
        });
        if (result) {
            loadItems();
        }
    };

    const deleteForm = async () => {
        if (!confirm('Eliminare?')) {
            return;
        }

        setOpenForm({
            ...openForm,
            isPosting: true,
        });
        const result = await deleteItem(openForm.editElement.id);
        setAlertStatus({
            open: true,
            success: result,
        });
        setOpenForm({
            ...openForm,
            open: false,
            isPosting: false,
        });
        if (result) {
            loadItems();
        }
    };

    const today = new Date();

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 2,
                            minHeight: 240,
                            mb: 8
                        }}>
                        <Title>Da Fare</Title>
                        <List dense>
                            {items.map((i) => {
                                const lastReset = i.lastResetTimestamp ? new Date(i.lastResetTimestamp) : undefined;
                                const nextReset = lastReset ? new Date(lastReset.getFullYear(),
                                    lastReset.getMonth(),
                                    lastReset.getDate() + (i.nominalExpiryDays ?? 0)) : undefined;
                                const isOld = !!nextReset && nextReset <= today;
                                const isTomorrow = !!nextReset && nextReset.getTime() == new Date(
                                    today.getFullYear(),
                                    today.getMonth(),
                                    today.getDate() + 1).getTime();

                                return <ListItem
                                    secondaryAction={
                                        <IconButton onClick={() => handleReset(i)} edge="end" aria-label="reset"><EventRepeat /></IconButton>
                                    }
                                    divider
                                >
                                    <ListItemAvatar>
                                        <Badge variant="dot" color={isOld ? "error" : "warning"} invisible={!isOld && !isTomorrow}>
                                            {!!nextReset && format(nextReset, 'dd/MM')}
                                        </Badge>
                                    </ListItemAvatar>
                                    <ListItemText
                                        onClick={() => handleEdit(i)}
                                        primary={i.name}
                                        secondary={"Ogni"
                                            + (i.nominalExpiryDays ?? 0 > 1 ? " " + i.nominalExpiryDays : "")
                                            + " giorn" + (i.nominalExpiryDays ?? 0 > 1 ? "i" : "o") + " - ultimo il " + (!!i.lastResetTimestamp && format(new Date(i.lastResetTimestamp), 'dd/MM/yyyy')) + " da " + i.lastResetBy?.name}
                                    />
                                </ListItem>;
                            }
                            )}
                        </List>
                        <Snackbar
                            open={alertStatus.open}
                            autoHideDuration={6000}
                            onClose={closeAlert}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                            <Alert variant="filled"
                                elevation={6}
                                severity={alertStatus.success ? 'success' : 'warning'}
                                sx={{ width: '100%' }}>
                                {alertStatus.success ? 'Okay!' : 'Errore!'}</Alert>
                        </Snackbar>
                    </Paper>
                </Grid>
            </Grid >
            <Fab color="primary"
                aria-label="add"
                size="medium"
                style={{ position: 'fixed', bottom: '2vh', right: '2vh' }}
                onClick={openAddForm}>
                <Add />
            </Fab>
            <TodoForm
                state={openForm}
                onCancel={closeForm}
                onSave={saveForm}
                onDelete={deleteForm}
                onEditElement={(ne) => {
                    setOpenForm({
                        ...openForm,
                        editElement: ne,
                    });
                }}
            />
        </>
    );
}