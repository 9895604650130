import Title from '../title/Title';
import {
    Grid,
    Box,
    CircularProgress,
    Paper,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Snackbar,
    Alert,
} from '@mui/material';

import * as React from 'react';
import { useParams } from 'react-router-dom';
import useExpenses from '../../hooks/useExpenses/useExpenses';
import { ExpenseType, LedgerAccount, LedgerItem } from '../../hooks/useExpenses/useExpenses.model';
import { format } from 'date-fns';

export type SearchStatus = {
    readonly loading: boolean;
    readonly error?: string;
    readonly items?: LedgerItem[];
};

export default function ExpenseSearch() {
    const [{ getTypes, getAccounts, searchItems }] = useExpenses();
    const [searchStatus, setSearchStatus] = React.useState<SearchStatus>({ loading: true });
    const [types, setTypes] = React.useState<ExpenseType[]>([]);
    const [accounts, setAccounts] = React.useState<LedgerAccount[]>([]);
    const [alertStatus, setAlertStatus] = React.useState({ open: false, success: false });
    const { query } = useParams();

    const closeAlert = () => setAlertStatus({
        open: false,
        success: alertStatus.success,
    });

    const loadItems = async (query: string) => {
        try {
            const i = await searchItems(query);
            setSearchStatus({
                items: i,
                loading: false
            });
        } catch (e) {
            setSearchStatus({
                items: [],
                loading: false,
                error: (e as Error).message
            })
        }
    };

    const loadInit = async () => {
        if (accounts.length || types.length) {
            return;
        }
        const [locAccounts, locTypes] = await Promise.all([
            getAccounts(),
            getTypes(),
        ]);
        setTypes(locTypes);
        setAccounts(locAccounts);
    };

    const presentType = (type: ExpenseType): string => {
        return (type.parent ? presentType(type.parent) + " > " : "")
            + type.name;
    };

    React.useEffect(() => {
        loadItems(query || '');
        loadInit();
    }, []);

    return (<>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper
                    sx={{
                        p: 2,
                        minHeight: 240,
                        mb: 8,
                    }}>
                    <Title>Ricerca spese: {query}</Title>
                    {searchStatus.loading
                        ? <Box display="flex" justifyContent="center">
                            <CircularProgress />
                        </Box>
                        :
                        <List dense>
                            {searchStatus.items?.map((i) => {
                                return <ListItem divider>
                                    <ListItemAvatar sx={{ mr: '1em' }}>
                                        {format(new Date(i.referenceDate ?? ''), 'dd/MM/yyyy')}
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={presentType(i.type!) + (i.notes ? " - " + i.notes : "")}
                                        secondary={i.account?.name}
                                    />
                                    <div>{i.amount?.toFixed(2)}</div>
                                </ListItem>;
                            }
                            )}
                            {searchStatus.items?.length === 0
                                ? <><p>Nessun risultato trovato</p>
                                    {searchStatus.error ? <p><small>({searchStatus.error})</small></p> : <></>}
                                </>
                                : <></>}
                        </List>
                    }
                    <Snackbar
                        open={alertStatus.open}
                        autoHideDuration={3000}
                        onClose={closeAlert}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                        <Alert variant="filled"
                            elevation={6}
                            severity={alertStatus.success ? 'success' : 'warning'}
                            sx={{ width: '100%' }}>
                            {alertStatus.success ? 'Okay!' : 'Errore!'}</Alert>
                    </Snackbar>
                </Paper>
            </Grid >
        </Grid >
    </>
    );
}