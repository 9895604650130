import Title from '../title/Title';
import { Grid, Paper, List, ListItem, Typography, ListItemText, Divider, LinearProgress } from '@mui/material';
import * as React from 'react';
import { format } from 'date-fns';
import useTodoItems from '../../hooks/useTodoItems/useTodoItems';
import { TodoItem } from '../../hooks/useTodoItems/useTodoItems.model';
import useStats from '../../hooks/useStats/useStats';
import { ThisMonthProgress } from '../../hooks/useStats/useStats.model';
import ExpenseElement from './ExpenseElement';

export default function Dashboard() {
    const [{ getItems }] = useTodoItems();
    const [itemsToday, setItemsToday] = React.useState<TodoItem[]>([]);
    const [itemsTomorrow, setItemsTomorrow] = React.useState<TodoItem[]>([]);
    const [{ getThisMonth }] = useStats();
    const [thisMonth, setThisMonth] = React.useState<ThisMonthProgress>();
    const [loadingItems, setLoadingItems] = React.useState(true);
    const [loadingStats, setLoadingStats] = React.useState(true);

    const loadTodoItems = async () => {
        const i = await getItems();
        setItemsToday(i.filter((i) => {
            if (!i.lastResetTimestamp) {
                return true;
            }
            const lastReset = new Date(i.lastResetTimestamp);
            const nextReset = new Date(lastReset.getFullYear(),
                lastReset.getMonth(),
                lastReset.getDate() + (i.nominalExpiryDays ?? 0));
            return nextReset <= today;
        }));
        setItemsTomorrow(i.filter((i) => {
            if (!i.lastResetTimestamp) {
                return false;
            }
            const lastReset = new Date(i.lastResetTimestamp);
            const nextReset = new Date(lastReset.getFullYear(),
                lastReset.getMonth(),
                lastReset.getDate() + (i.nominalExpiryDays ?? 0));
            return nextReset.getTime() == new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate() + 1).getTime();
        }));
        setLoadingItems(false);
    };

    const updateData = async () => {
        const rp = await getThisMonth();
        setThisMonth(rp);
        setLoadingStats(false);
    };

    React.useEffect(() => {
        updateData();
        loadTodoItems();
    }, []);


    const today = new Date();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: 240,
                    }}>
                    <Title>Questo mese</Title>
                    {loadingStats && <LinearProgress />}
                    <ExpenseElement
                        title={'Totale'}
                        cur={thisMonth?.totalCur}
                        avg={thisMonth?.totalAvg} />
                    {thisMonth && thisMonth.types?.length > 2 ?
                        <Grid container spacing={0}>
                            <ExpenseElement
                                title={thisMonth.types[0].label}
                                cur={thisMonth.types[0].cur}
                                avg={thisMonth.types[0].avg} />
                            <Divider orientation="vertical" flexItem variant="middle" />
                            <ExpenseElement
                                title={thisMonth.types[1].label}
                                cur={thisMonth.types[1].cur}
                                avg={thisMonth.types[1].avg} />
                            <Divider orientation="vertical" flexItem variant="middle" />
                            <ExpenseElement
                                title={thisMonth.types[2].label}
                                cur={thisMonth.types[2].cur}
                                avg={thisMonth.types[2].avg} />
                        </Grid>
                        : null}
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: 240,
                    }}>
                    <Title>Da Fare</Title>
                    <Typography component="div" variant="subtitle1" gutterBottom>
                        Oggi
                    </Typography>
                    {loadingItems && <LinearProgress />}
                    {(loadingItems || itemsToday.length) ? <></> : <p style={{ fontStyle: 'italic' }}>Nessun dato da mostrare</p>}
                    <List dense>
                        {itemsToday
                            .map((i) => {
                                return <ListItem divider>
                                    <ListItemText
                                        primary={i.name}
                                        secondary={"Ogni"
                                            + (i.nominalExpiryDays ?? 0 > 1 ? " " + i.nominalExpiryDays : "")
                                            + " giorn" + (i.nominalExpiryDays ?? 0 > 1 ? "i" : "o") + " - ultimo il " + (!!i.lastResetTimestamp && format(new Date(i.lastResetTimestamp), 'dd/MM/yyyy')) + " da " + i.lastResetBy?.name}
                                    />
                                </ListItem>;
                            }
                            )}
                    </List>
                    <Typography component="div" variant="subtitle1" gutterBottom>
                        Domani
                    </Typography>
                    {loadingItems && <LinearProgress />}
                    {(loadingItems || itemsTomorrow.length) ? <></> : <p style={{ fontStyle: 'italic' }}>Nessun dato da mostrare</p>}
                    <List dense>
                        {itemsTomorrow
                            .map((i) => {
                                return <ListItem divider>
                                    <ListItemText
                                        primary={i.name}
                                        secondary={"Ogni"
                                            + (i.nominalExpiryDays ?? 0 > 1 ? " " + i.nominalExpiryDays : "")
                                            + " giorn" + (i.nominalExpiryDays ?? 0 > 1 ? "i" : "o") + " - ultimo il " + (!!i.lastResetTimestamp && format(new Date(i.lastResetTimestamp), 'dd/MM/yyyy')) + " da " + i.lastResetBy?.name}
                                    />
                                </ListItem>;
                            }
                            )}
                    </List>
                </Paper>
            </Grid>
        </Grid>
    );
}