import Title from '../title/Title';
import {
    Grid,
    Paper,
    Badge,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    IconButton,
    Snackbar,
    Alert,
    Fab,
} from '@mui/material';

import {
    EventRepeat,
    Add
} from '@mui/icons-material';
import * as React from 'react';
import { format } from 'date-fns';
import { IDialogState } from './forms/RecurringExpense.model';
import RecurringExpenseForm from './forms/RecurringExpenseForm';
import useRecurringExpenses from '../../hooks/useRecurringExpenses/useRecurringExpenses';
import { RecurringExpense } from '../../hooks/useRecurringExpenses/useRecurringExpenses.model';
import { ExpenseType, LedgerAccount } from '../../hooks/useExpenses/useExpenses.model';
import useExpenses from '../../hooks/useExpenses/useExpenses';

export default function RecurringExpenses() {
    const [{ getTypes, getAccounts }] = useExpenses();
    const [{ getItems, upsertItem, deleteItem }] = useRecurringExpenses();
    const [items, setItems] = React.useState<RecurringExpense[]>([]);
    const [types, setTypes] = React.useState<ExpenseType[]>([]);
    const [accounts, setAccounts] = React.useState<LedgerAccount[]>([]);
    const [alertStatus, setAlertStatus] = React.useState({ open: false, success: false });
    const [openForm, setOpenForm] = React.useState<IDialogState>({ open: false, isCreation: false, editElement: undefined, isPosting: false });

    const closeAlert = () => setAlertStatus({
        open: false,
        success: alertStatus.success,
    });

    const loadItems = async () => {
        const i = await getItems();
        setItems(i);
    };

    const loadInit = async () => {
        if (accounts.length || types.length) {
            return;
        }
        const [locAccounts, locTypes] = await Promise.all([
            getAccounts(),
            getTypes(),
        ]);
        setTypes(locTypes);
        setAccounts(locAccounts);
    };

    React.useEffect(() => {
        loadItems();
        loadInit();
    }, []);

    const handleEdit = async (item: RecurringExpense) => {
        setOpenForm({
            open: true,
            isCreation: false,
            editElement: {
                id: item.id,
                referenceDate: item.referenceDate,
                entered: item.entered,
                period: item.period,
                amount: item.amount,
                isEnabled: item.isEnabled,
                notes: item.notes,
                typeId: item.type.id,
                accountId: item.account.id,
                amountStr: item.amount.toFixed(2),
            },
            isPosting: false,
        });
    };

    const openAddForm = () => {
        const now = new Date();
        const today = new Date(now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            12,
            0,
            0);
        setOpenForm({
            open: true,
            isCreation: true,
            editElement: {
                id: undefined,
                referenceDate: today.toISOString(),
                entered: today.toISOString(),
                period: 0,
                amount: 0,
                isEnabled: true,
                typeId: 0,
                accountId: 0,
                amountStr: '',
            },
            isPosting: false,
        });
    };

    const closeForm = () => {
        setOpenForm({
            ...openForm,
            open: false,
        });
    };

    const saveForm = async () => {
        setOpenForm({
            ...openForm,
            isPosting: true,
        });
        const result = openForm.editElement && await upsertItem(openForm.editElement);
        setAlertStatus({
            open: true,
            success: !!result,
        });
        setOpenForm({
            ...openForm,
            open: false,
            isPosting: false,
        });
        if (result) {
            loadItems();
        }
    };

    const deleteForm = async () => {
        if (!confirm('Eliminare?')) {
            return;
        }

        setOpenForm({
            ...openForm,
            isPosting: true,
        });
        const result = openForm.editElement && await deleteItem(openForm.editElement.id!);
        setAlertStatus({
            open: true,
            success: !!result,
        });
        setOpenForm({
            ...openForm,
            open: false,
            isPosting: false,
        });
        if (result) {
            loadItems();
        }
    };

    const today = new Date();

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 2,
                            minHeight: 240,
                            mb: 8
                        }}>
                        <Title>Spese Ricorrenti</Title>
                        <List dense>
                            {items.map((i) => {
                                const reference = i.referenceDate ? new Date(i.referenceDate) : undefined;
                                return <ListItem divider>
                                    <ListItemAvatar>
                                        {!!reference && format(reference, 'dd/MM')}
                                    </ListItemAvatar>
                                    <ListItemText
                                        onClick={() => handleEdit(i)}
                                        primary={i.type.name + (i.notes ? " (" + i.notes + ")" : "")}
                                        secondary={"Ogni " + (i.period == 0 ? "1" : "12") + " mesi."}
                                    />
                                </ListItem>;
                            }
                            )}
                        </List>
                        <Snackbar
                            open={alertStatus.open}
                            autoHideDuration={6000}
                            onClose={closeAlert}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                            <Alert variant="filled"
                                elevation={6}
                                severity={alertStatus.success ? 'success' : 'warning'}
                                sx={{ width: '100%' }}>
                                {alertStatus.success ? 'Okay!' : 'Errore!'}</Alert>
                        </Snackbar>
                    </Paper>
                </Grid>
            </Grid >
            <Fab color="primary"
                aria-label="add"
                size="medium"
                style={{ position: 'fixed', bottom: '2vh', right: '2vh' }}
                onClick={openAddForm}>
                <Add />
            </Fab>
            <RecurringExpenseForm
                state={openForm}
                onCancel={closeForm}
                onSave={saveForm}
                onDelete={deleteForm}
                onEditElement={(ne) => {
                    setOpenForm({
                        ...openForm,
                        editElement: ne,
                    });
                }}
                types={types}
                accounts={accounts}
            />
        </>
    );
}