import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMilesAuth from '../../hooks/useMilesAuth/useMilesAuth';
import { useNavigate } from 'react-router-dom';

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://miles.alex-maz.info">
                Alex Mazzariol
            </Link>{' '}
            {new Date().getFullYear()}.
        </Typography>
    );
}

const theme = createTheme();

function SignIn() {
    const [{ login }] = useMilesAuth();
    const navigate = useNavigate();
    const [state, setState] = React.useState({ open: false, loading: false });

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setState({
            open: false,
            loading: true,
        });
        if (await login({
            username: data.get('username')?.toString(),
            password: data.get('password')?.toString(),
        })) {
            console.log('Logged in, redirecting.');
            navigate('/');
        } else {
            setState({
                open: true,
                loading: false,
            });
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} src="logo.png" />
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <LoadingButton
                            type="submit"
                            fullWidth
                            variant="contained"
                            loading={state.loading}
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </LoadingButton>
                    </Box>
                </Box><Snackbar
                    open={state.open}
                    autoHideDuration={6000}
                    onClose={() => setState({ ...state, open: false })}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert variant="filled"
                        elevation={6}
                        severity="warning"
                        sx={{ width: '100%' }}>Credenziali non valide!</Alert>
                </Snackbar>
                <Copyright />
            </Container>
        </ThemeProvider>
    );
}

export default SignIn;