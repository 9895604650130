import Title from '../title/Title';
import {
    Grid,
    Box,
    CircularProgress,
    Paper,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    IconButton,
    Snackbar,
    Alert,
    Stack,
    SpeedDial,
    SpeedDialAction,
} from '@mui/material';

import {
    Edit,
    Add,
    NavigateBefore,
    NavigateNext,
    Search,
    MoreVert,
    CurrencyExchange,
} from '@mui/icons-material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import useExpenses from '../../hooks/useExpenses/useExpenses';
import { ExpenseType, LedgerAccount, LedgerItem } from '../../hooks/useExpenses/useExpenses.model';
import { format } from 'date-fns';
import { IDialogState } from './entryForm/EntryForm.model';
import EntryForm from './entryForm/EntryForm';

export default function Expenses() {
    const [{ getItems, getTypes, getAccounts, upsertItem, deleteItem }] = useExpenses();
    const [items, setItems] = React.useState<LedgerItem[]>([]);
    const [types, setTypes] = React.useState<ExpenseType[]>([]);
    const [accounts, setAccounts] = React.useState<LedgerAccount[]>([]);
    const [alertStatus, setAlertStatus] = React.useState({ open: false, success: false });
    const [openForm, setOpenForm] = React.useState<IDialogState>({ open: false, isCreation: false, editElement: undefined, isPosting: false });
    const [selectedMonth, setSelectedMonth] = React.useState({ year: new Date().getFullYear(), month: new Date().getMonth() + 1, isLoading: false });
    const navigate = useNavigate();

    const closeAlert = () => setAlertStatus({
        open: false,
        success: alertStatus.success,
    });

    const loadItems = async (year?: number, month?: number) => {
        setSelectedMonth({
            year: year ?? selectedMonth.year,
            month: month ?? selectedMonth.month,
            isLoading: true
        });
        const i = await getItems(
            year ?? selectedMonth.year,
            month ?? selectedMonth.month);
        setItems(i);
        setSelectedMonth({
            year: year ?? selectedMonth.year,
            month: month ?? selectedMonth.month,
            isLoading: false
        });
    };

    const loadInit = async () => {
        if (accounts.length || types.length) {
            return;
        }
        const [locAccounts, locTypes] = await Promise.all([
            getAccounts(),
            getTypes(),
        ]);
        setTypes(locTypes);
        setAccounts(locAccounts);
    };

    const presentType = (type: ExpenseType): string => {
        return (type.parent ? presentType(type.parent) + " > " : "")
            + type.name;
    };

    React.useEffect(() => {
        loadItems();
        loadInit();
    }, []);

    const prevMonth = async () => {
        const nmonth = selectedMonth.month - 1;
        const nsel = {
            month: nmonth == 0 ? 12 : nmonth,
            year: selectedMonth.year + (nmonth == 0 ? -1 : 0),
            isLoading: true,
        };
        await loadItems(nsel.year, nsel.month);
    };

    const nextMonth = async () => {
        const nmonth = selectedMonth.month + 1;
        const nsel = {
            month: nmonth == 13 ? 1 : nmonth,
            year: selectedMonth.year + (nmonth == 13 ? 1 : 0),
            isLoading: true,
        };
        await loadItems(nsel.year, nsel.month);
    };

    const openAddForm = () => {
        const now = new Date();
        const today = new Date(now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            12,
            0,
            0);
        setOpenForm({
            open: true,
            isCreation: true,
            editElement: {
                id: undefined,
                entered: new Date().toISOString(),
                referenceDate: today.toISOString().substring(0, 10),
                amountStr: '',
                enteredByUserId: 0,
                accountId: 0,
                typeId: 0,
            },
            isPosting: false,
        });
    };

    const openEditForm = (i: LedgerItem) => {
        setOpenForm({
            open: true,
            isCreation: false,
            editElement: {
                id: i.id,
                entered: i.entered,
                referenceDate: i.referenceDate,
                amountStr: i.amount?.toFixed(2) ?? '',
                amount: i.amount,
                enteredByUserId: 0,
                accountId: i.account?.id ?? 0,
                typeId: i.type?.id ?? 0,
                notes: i.notes,
            },
            isPosting: false,
        });
    };

    const closeForm = () => {
        setOpenForm({
            ...openForm,
            open: false,
        });
    };

    const saveForm = async () => {
        setOpenForm({
            ...openForm,
            isPosting: true,
        });
        const result = await upsertItem(openForm.editElement!);
        setAlertStatus({
            open: true,
            success: result,
        });
        setOpenForm({
            ...openForm,
            open: false,
            isPosting: false,
        });
        if (result) {
            loadItems();
        }
    };

    const deleteForm = async () => {
        if (!confirm('Eliminare?')) {
            return;
        }

        setOpenForm({
            ...openForm,
            isPosting: true,
        });
        const result = await deleteItem(openForm.editElement!.id!);
        setAlertStatus({
            open: true,
            success: result,
        });
        setOpenForm({
            ...openForm,
            open: false,
            isPosting: false,
        });
        if (result) {
            loadItems();
        }
    };

    const openSearchForm = async () => {
        const searchTerms = prompt('Testo della ricerca');
        if (searchTerms) {
            console.log(`Searching $searchTerms`);
            navigate(`/expenses/${searchTerms}`);
        }
    };

    return (<>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper
                    sx={{
                        p: 2,
                        minHeight: 240,
                        mb: 8,
                    }}>
                    <Title>Spese</Title>
                    <Stack direction="row" justifyContent="center" spacing={3}>
                        <Button variant="contained" onClick={prevMonth}>
                            <NavigateBefore />
                        </Button>
                        <h3>
                            {format(new Date(selectedMonth.year, selectedMonth.month - 1, 1), 'MMM yyyy')}
                        </h3>
                        <Button variant="contained" onClick={nextMonth}>
                            <NavigateNext />
                        </Button>
                    </Stack>
                    {selectedMonth.isLoading
                        ? <Box display="flex" justifyContent="center">
                            <CircularProgress />
                        </Box>
                        :
                        <List dense>
                            {items.map((i) => {
                                return <ListItem
                                    secondaryAction={
                                        <IconButton onClick={() => openEditForm(i)} edge="end" aria-label="edit"><Edit /></IconButton>
                                    }
                                    divider
                                >
                                    <ListItemAvatar>
                                        {format(new Date(i.referenceDate ?? ''), 'dd/MM')}
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={presentType(i.type!) + (i.notes ? " - " + i.notes : "")}
                                        secondary={i.account?.name}
                                    />
                                    <div>{i.amount?.toFixed(2)}</div>
                                </ListItem>;
                            }
                            )}
                        </List>
                    }
                    <Snackbar
                        open={alertStatus.open}
                        autoHideDuration={3000}
                        onClose={closeAlert}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                        <Alert variant="filled"
                            elevation={6}
                            severity={alertStatus.success ? 'success' : 'warning'}
                            sx={{ width: '100%' }}>
                            {alertStatus.success ? 'Okay!' : 'Errore!'}</Alert>
                    </Snackbar>
                </Paper>
            </Grid >
        </Grid >
        <SpeedDial
            ariaLabel='actions'
            sx={{ position: 'fixed', bottom: '2vh', right: '2vw' }}
            icon={<MoreVert />}
        >
            <SpeedDialAction
                key='add'
                icon={<Add />}
                tooltipTitle="Aggiungi"
                onClick={openAddForm} />
            <SpeedDialAction
                key='recurring'
                icon={<CurrencyExchange />}
                tooltipTitle="Periodiche"
                onClick={() => navigate('/recurring-expenses')} />
            <SpeedDialAction
                key='search'
                icon={<Search />}
                tooltipTitle="Cerca"
                onClick={openSearchForm} />
        </SpeedDial>
        <EntryForm
            accounts={accounts}
            types={types}
            state={openForm}
            onCancel={closeForm}
            onSave={saveForm}
            onDelete={deleteForm}
            onEditElement={(ne) => {
                setOpenForm({
                    ...openForm,
                    editElement: ne,
                });
            }}
        />
    </>
    );
}