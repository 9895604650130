import { useCallback } from 'react';
import useMilesAuth from '../useMilesAuth/useMilesAuth';

import {
  LedgerItem,
  ExpenseType,
  UseExpenses,
  LedgerAccount,
  LedgerItemInput,
} from './useExpenses.model';

const useExpenses: UseExpenses = () => {
  const [{ loggedAxios }] = useMilesAuth();

  const getItems = useCallback(
    async (year: number, month: number): Promise<LedgerItem[]> => {
      try {
        const result = await loggedAxios().get<LedgerItem[]>(`/api/ledger/${year}/${month}`);

        if (result.status == 200) {
          return result.data;
        }
      } catch (error) {
        console.error('Unable to get items', error);
      }

      throw new Error('Unable to get items');
    },
    [],
  );

  const searchItems = useCallback(
    async (query: string): Promise<LedgerItem[]> => {
      try {
        const result = await loggedAxios().get<LedgerItem[]>(`/api/ledger/search/${query}`);

        if (result.status == 200) {
          return result.data;
        }
      } catch (error) {
        console.error('Unable to search items', error);
      }

      throw new Error('Unable to search items');
    },
    [],
  );

  const getTypes = useCallback(
    async (): Promise<ExpenseType[]> => {
      try {
        const result = await loggedAxios().get<ExpenseType[]>('/api/expensetypes');

        if (result.status == 200) {
          return result.data;
        }
      } catch (error) {
        console.error('Unable to get types', error);
      }

      throw new Error('Unable to get types');
    },
    [],
  );

  const getAccounts = useCallback(
    async (): Promise<LedgerAccount[]> => {
      try {
        const result = await loggedAxios().get<LedgerAccount[]>('/api/accounts');

        if (result.status == 200) {
          return result.data;
        }
      } catch (error) {
        console.error('Unable to get accounts', error);
      }

      throw new Error('Unable to get accounts');
    },
    [],
  );

  const upsertItem = useCallback(
    async (item: LedgerItemInput) => {
      try {
        const result = item.id
          ? await loggedAxios()
            .put<LedgerItemInput, void>(
              `/api/ledger/${item.id}`,
              item,
            )
          : await loggedAxios()
            .post<LedgerItemInput, void>(
              '/api/ledger',
              item,
            );

        if (result.status >= 200 && result.status < 400) {
          return true;
        }
      } catch (error) {
        console.error('Unable to upsert item!', error);
      }

      return false;
    },
    [],
  );

  const deleteItem = useCallback(
    async (id: number) => {
      try {
        const result = await loggedAxios()
          .delete<void>(
            `/api/ledger/${id}`,
          );

        if (result.status >= 200 && result.status < 400) {
          return true;
        }
      } catch (error) {
        console.error('Unable to delete item!', error);
      }

      return false;
    },
    [],
  );

  return [{ getItems, getTypes, getAccounts, upsertItem, deleteItem, searchItems }];
};

export default useExpenses;
