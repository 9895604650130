import axios, { AxiosError, AxiosResponse } from 'axios';
import { useCallback } from 'react';
import useMilesLocalStorage from '../useMilesLocalStorage/useMilesLocalStorage';

import {
  UseMilesAuth,
  LoginParams,
  LoginResponse,
} from './useMilesAuth.model';

const useMilesAuth: UseMilesAuth = () => {
  const [localStorage, { setLocalStorage, clearLocalStorage }] = useMilesLocalStorage();

  const refresh = async () => {
    try {
      const result = await axios.post<
        void,
        AxiosResponse<LoginResponse>>(
          'api/login/renew',
          null,
          loggedAxiosConfig(),
        );

      if (result.status == 200 && result.data.token) {
        console.debug(`Renew token ok for ${result.data.name}`);
        setLocalStorage({
          jwt: result.data.token,
          userId: result.data.id,
          username: result.data.name,
        });

        setTimeout(refresh, 3 * 60 * 60 * 1000);
      }
    } catch (error) {
      console.error('Unable to refresh current token', error);
    }
  };

  const changePw = useCallback(
    async (loginData: LoginParams) => {
      try {
        const result = await axios.post<
          LoginParams,
          AxiosResponse<LoginResponse>>(
            'api/login/change-password',
            loginData,
            loggedAxiosConfig(),
          );

        return true;
      } catch (error) {
        console.error(`Unable to change password for ${loginData.username}`, error);

        const ae = error as AxiosError;
        if (ae?.response?.status == 401) {
          console.debug('Unauthenticated token!');
          clearLocalStorage();

          window.location.reload();
        }
      }

      return false;
    },
    [],
  );

  const login = useCallback(
    async (loginData: LoginParams) => {
      try {
        const result = await axios.post<
          LoginParams,
          AxiosResponse<LoginResponse>>(
            'api/login',
            loginData,
          );

        if (result.status == 200 && result.data.token) {
          setLocalStorage({
            jwt: result.data.token,
            userId: result.data.id,
            username: result.data.name,
          });

          setTimeout(refresh, 6 * 60 * 60 * 1000);

          return true;
        }
      } catch (error) {
        console.error(`Unable to login ${loginData.username}`, error);
      }

      return false;
    },
    [],
  );

  const isLoggedIn = () => {
    const jwt = localStorage.jwt;
    return !!jwt;
  };

  const loggedAxiosConfig = () => {
    return {
      headers: {
        'Authorization': `Bearer ${localStorage.jwt}`,
      },
    };
  };

  const loggedAxios = () => {
    return {
      post: async <B, R,>(url: string, body: any) => {
        try {
          return await axios.post<
            B,
            AxiosResponse<R>>(
              url,
              body,
              loggedAxiosConfig(),
            );
        } catch (error) {
          const ae = error as AxiosError;
          if (ae?.response?.status == 401) {
            console.debug('Unauthenticated token!');
            clearLocalStorage();

            window.location.reload();
          }

          throw error;
        }
      },
      put: async <B, R,>(url: string, body: any) => {
        try {
          return await axios.put<
            B,
            AxiosResponse<R>>(
              url,
              body,
              loggedAxiosConfig(),
            );
        } catch (error) {
          const ae = error as AxiosError;
          if (ae?.response?.status == 401) {
            console.debug('Unauthenticated token!');
            clearLocalStorage();

            window.location.reload();
          }

          throw error;
        }
      },
      get: async <R,>(url: string) => {
        try {
          return await axios.get<
            void,
            AxiosResponse<R>>(
              url,
              loggedAxiosConfig(),
            );
        } catch (error) {
          const ae = error as AxiosError;
          if (ae?.response?.status == 401) {
            console.debug('Unauthenticated token!');
            clearLocalStorage();

            window.location.reload();
          }

          throw error;
        }
      },
      delete: async <R,>(url: string) => {
        try {
          return await axios.delete<
            void,
            AxiosResponse<R>>(
              url,
              loggedAxiosConfig(),
            );
        } catch (error) {
          const ae = error as AxiosError;
          if (ae?.response?.status == 401) {
            console.debug('Unauthenticated token!');
            clearLocalStorage();

            window.location.reload();
          }

          throw error;
        }
      }
    };
  };

  return [{ login, isLoggedIn, loggedAxiosConfig, changePw, loggedAxios, refresh }];
};

export default useMilesAuth;
