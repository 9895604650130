import * as React from 'react';
import useMilesLocalStorage from '../../hooks/useMilesLocalStorage/useMilesLocalStorage';

function SignOut() {
    const [, { clearLocalStorage }] = useMilesLocalStorage();

    React.useEffect(() => {
        clearLocalStorage();
        window.location.reload();
    });

    return <></>;
}

export default SignOut;