import * as React from 'react';
import Title from '../title/Title';
import {
    Grid,
    Paper,
} from '@mui/material';
import useStats from '../../hooks/useStats/useStats';
import {
    HistoryPoint, RatioPoint
} from '../../hooks/useStats/useStats.model';
import Chart, { CommonSeriesSettings, Legend, Point, Tooltip } from 'devextreme-react/chart';
import PieChart, {
    Series,
    Title as ChartTitle,
    Label,
    Connector,
} from 'devextreme-react/pie-chart';
import { Format } from 'devextreme/localization';
import { RatioDisplayPoint, SplitHistoryCache } from './Statistics.model';
import './Statistics.css';

export default function Statistics() {
    const [{
        getTotalHistory,
        getTypeRatios,
        getAccountRatios,
        getTotalAccountHistory,
        getTotalTypeHistory,
        getTypeRatioExtras,
        getTypeRatioHousing,
        getTypeRatioMeals,
    }] = useStats();
    const [totalHistory, setTotalHistory] = React.useState([] as HistoryPoint[]);
    const [totalAccountHistory, setTotalAccountHistory] = React.useState<SplitHistoryCache>();
    const [totalTypeHistory, setTotalTypeHistory] = React.useState<SplitHistoryCache>();
    const [typeRatio, setTypeRatio] = React.useState<RatioDisplayPoint[]>([]);
    const [typeRatioHousing, setTypeRatioHousing] = React.useState<RatioDisplayPoint[]>([]);
    const [typeRatioExtras, setTypeRatioExtras] = React.useState<RatioDisplayPoint[]>([]);
    const [typeRatioMeals, setTypeRatioMeals] = React.useState<RatioDisplayPoint[]>([]);
    const [accountRatio, setAccountRatio] = React.useState([{ label: '', val: 0 }]);

    const updateData = async () => {
        const [th, tr, ar] = await Promise.all([
            getTotalHistory(),
            getTypeRatios(),
            getAccountRatios(),
        ]);
        setTotalHistory(th);

        setTypeRatio(tr.labels.map((l, i) => ({
            label: l,
            val: tr.data[i]
        })));
        setAccountRatio(ar.labels.map((l, i) => ({
            label: l,
            val: ar.data[i]
        })));

        const [tah, tth, trE, trH, trM] = await Promise.all([
            getTotalAccountHistory(),
            getTotalTypeHistory(),
            getTypeRatioExtras(),
            getTypeRatioHousing(),
            getTypeRatioMeals(),
        ]);
        const tthProperties = [...Object.entries(tth.data[0])
            .map(([propName]) => propName)];
        const ttH = [...tth.data.map((data, index) => ({ label: tth.labels[index], ...data }))];
        setTotalTypeHistory({
            history: ttH,
            properties: tthProperties,
        });
        const tahProperties = [...Object.entries(tah.data[0])
            .map(([propName]) => propName)];
        setTotalAccountHistory({
            history: [...tah.data.map((data, index) => ({ label: tah.labels[index], ...data }))],
            properties: tahProperties,
        });
        setTypeRatioMeals(trM.labels.map((l, i) => ({
            label: l,
            val: trM.data[i]
        })));
        setTypeRatioHousing(trH.labels.map((l, i) => ({
            label: l,
            val: trH.data[i]
        })));
        setTypeRatioExtras(trE.labels.map((l, i) => ({
            label: l,
            val: trE.data[i]
        })));
    };

    const format: Format = { type: 'currency', currency: 'EUR' };

    const cleanAT = (argumentText: string) => argumentText.replace(/^[0-9]+ /, '');

    const labelCustomizer = (pointInfo: any) => {
        return (pointInfo.points && pointInfo.points.length)
            ? `<p class='tooltip-title'>${cleanAT(pointInfo.argumentText)}</p>${pointInfo.points
                .map((p: any) => `${p.seriesName}<span class='tooltip-val'>${p.valueText}</span>`)
                .join('<br>')}`
            : `<span class='tooltip-title'>${cleanAT(pointInfo.argumentText)}</span><br>${pointInfo.valueText}`
    };

    const tooltipCustomizer = (pointInfo: any) => {
        return {
            html: labelCustomizer(pointInfo)
        };
    }

    React.useEffect(() => {
        updateData();
    }, []);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                    <Title>Totale delle Spese</Title>
                    <p>Ultimi 500 giorni</p>
                    <Chart
                        dataSource={totalHistory}
                        palette="Soft Pastel"
                    >
                        <Legend visible={false} />
                        <CommonSeriesSettings type="stackedsplinearea">
                            <Point visible={true} />
                        </CommonSeriesSettings>
                        <Series
                            valueField="value"
                            argumentField="label"
                        />
                        <Tooltip
                            enabled={true}
                            format={format}
                            customizeTooltip={tooltipCustomizer} />
                    </Chart>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                    <Title>Ripartizione Spese per Tipo</Title>
                    <p>Ultimi 6 mesi</p>
                    <PieChart
                        dataSource={typeRatio}
                        type="doughnut"
                        palette="Soft Pastel"
                    >
                        <Legend visible={false} />
                        <Series
                            valueField="val"
                            argumentField="label"
                        >
                            <Label
                                visible={true}
                                format={format}
                                customizeText={labelCustomizer}>
                                <Connector visible={true} />
                            </Label>
                        </Series>
                    </PieChart>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                    <Title>Ripartizione Spese per Conto</Title>
                    <p>Ultimi 4 mesi</p>
                    <PieChart
                        dataSource={accountRatio}
                        type="doughnut"
                        palette="Soft Pastel"
                    >
                        <Legend visible={false} />
                        <Series
                            valueField="val"
                            argumentField="label"
                        >
                            <Label
                                visible={true}
                                format={format}
                                customizeText={labelCustomizer}>
                                <Connector visible={true} />
                            </Label>
                        </Series>
                    </PieChart>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                    <Title>Totale per Tipo</Title>
                    <p>Ultimi 500 giorni</p>
                    <Chart
                        dataSource={totalTypeHistory?.history}
                        palette="Soft Pastel"
                    >
                        <CommonSeriesSettings
                            type="stackedsplinearea">
                            <Point visible={true} />
                        </CommonSeriesSettings>
                        {
                            totalTypeHistory?.properties.map(prop =>
                                <Series
                                    valueField={prop}
                                    name={cleanAT(prop)}
                                    argumentField="label" />
                            )
                        }
                        <Tooltip
                            enabled={true}
                            format={format}
                            shared={true}
                            customizeTooltip={tooltipCustomizer} />
                    </Chart>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                    <Title>Totale per Conto</Title>
                    <p>Ultimi 500 giorni</p>
                    <Chart
                        dataSource={totalAccountHistory?.history}
                        palette="Soft Pastel"
                    >
                        <CommonSeriesSettings
                            type="stackedsplinearea">
                            <Point visible={true} />
                        </CommonSeriesSettings>
                        {
                            totalAccountHistory?.properties.map(prop =>
                                <Series
                                    valueField={prop}
                                    name={cleanAT(prop)}
                                    argumentField="label" />
                            )
                        }
                        <Tooltip
                            enabled={true}
                            format={format}
                            shared={true}
                            customizeTooltip={tooltipCustomizer} />
                    </Chart>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                    <Title>Ripartizione Vitto</Title>
                    <p>Ultimi 6 mesi</p>
                    <PieChart
                        dataSource={typeRatioMeals}
                        type="doughnut"
                        palette="Soft Pastel"
                    >
                        <Legend visible={false} />
                        <Series
                            valueField="val"
                            argumentField="label"
                        >
                            <Label
                                visible={true}
                                format={format}
                                customizeText={labelCustomizer}>
                                <Connector visible={true} />
                            </Label>
                        </Series>
                    </PieChart>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                    <Title>Ripartizione Alloggio</Title>
                    <p>Ultimi 6 mesi</p>
                    <PieChart
                        dataSource={typeRatioHousing}
                        type="doughnut"
                        palette="Soft Pastel"
                    >
                        <Legend visible={false} />
                        <Series
                            valueField="val"
                            argumentField="label"
                        >
                            <Label
                                visible={true}
                                format={format}
                                customizeText={labelCustomizer}>
                                <Connector visible={true} />
                            </Label>
                        </Series>
                    </PieChart>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                    <Title>Ripartizione Extra</Title>
                    <p>Ultimi 6 mesi</p>
                    <PieChart
                        dataSource={typeRatioExtras}
                        type="doughnut"
                        palette="Soft Pastel"
                    >
                        <Legend visible={false} />
                        <Series
                            valueField="val"
                            argumentField="label"
                        >
                            <Label
                                visible={true}
                                format={format}
                                customizeText={labelCustomizer}>
                                <Connector visible={true} />
                            </Label>
                        </Series>
                    </PieChart>
                </Paper>
            </Grid>
        </Grid>
    );
}