import Title from '../title/Title';
import {
    Paper,
    Box,
    Slider,
    ToggleButton,
    ToggleButtonGroup
} from '@mui/material';
import * as React from 'react';
import { TheresaWsContext } from '../../contexts/TheresaContext';

export default function ACControl() {
    const context = React.useContext(TheresaWsContext);

    if (!context) {
        return <div>Caricamento...</div>;
    }

    const { acState, sendMessage } = context;

    const tempMarks = [
        { value: 17, label: '17°C' },
        { value: 19, label: '19°C' },
        { value: 21, label: '21°C' },
        { value: 23, label: '23°C' },
        { value: 25, label: '25°C' },
    ]

    const valuetext = (value: number) => {
        return `${value}°C`;
    };

    const presentStatus = () => {
        if (!acState) return 'Caricamento...';

        const action = acState?.acStatus == 0
            ? "Spento"
            : (acState?.acStatus == 1
                ? "Acceso"
                : "In spegnimento");

        const temp = acState?.acStatus == 1
            ? `, ${acState?.acTemp}°C`
            : '';

        return `${action}${temp}`;
    };

    return (<>
        <Paper
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                minHeight: '150px',
                minWidth: '300px',
            }}>
            <Title>Controllo</Title>
            <Box mb={2} display="flex" justifyContent="center">
                {<Slider sx={{ mx: 4 }}
                    aria-label="Temperatura"
                    defaultValue={21}
                    step={1}
                    valueLabelDisplay="auto"
                    min={17}
                    max={25}
                    disabled={!acState}
                    marks={tempMarks}
                    getAriaValueText={valuetext}
                    onChangeCommitted={(e, value) => {
                        if (value == acState?.acTemp) return;
                        if (acState?.acStatus !== 1) return;
                        sendMessage({
                            active: acState?.acStatus == 1,
                            temperature: value as number,
                        });
                    }}
                    value={acState?.acTemp ?? 21}
                />}
            </Box>
            <Box mb={2} display="flex" justifyContent="center">
                {<ToggleButtonGroup
                    value={acState?.acStatus == 1 ? 'on' : 'off'}
                    exclusive
                    disabled={!acState}
                    color="primary"
                    onChange={(e, nval) => sendMessage({
                        active: nval == 'on',
                        temperature: acState?.acTemp ?? 21,
                    })}
                >
                    <ToggleButton value="off">Off</ToggleButton>
                    <ToggleButton value="on">On</ToggleButton>
                </ToggleButtonGroup>}
            </Box>
            <Title>Stato</Title>
            <Box display="flex" justifyContent="center">
                {presentStatus()}
            </Box>
        </Paper>
    </>
    );
}