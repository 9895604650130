import { useCallback } from 'react';

import {
  UseMilesLocalStorage,
  UseMilesLocalStorageValue,
} from './useMilesLocalStorage.model';

import { useLocalStorage } from 'usehooks-ts';

const useMilesLocalStorage: UseMilesLocalStorage = initialValue => {
  const key = `Miles`;

  const [localStorageState, setLocalStorageState] =
    useLocalStorage<UseMilesLocalStorageValue>(key, initialValue || {});

  const setLocalStorage = useCallback(
    (value: any) => setLocalStorageState(prev => ({ ...prev, ...value })),
    [setLocalStorageState],
  );

  const clearLocalStorage = useCallback(
    () => localStorage.removeItem(key),
    [key],
  );

  return [localStorageState, { setLocalStorage, clearLocalStorage }];
};

export default useMilesLocalStorage;
