import * as React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { EntryFormProps } from './EntryForm.model';
import {
    Button,
    Stack,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    TextField,
    InputAdornment,
} from '@mui/material';

import { Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

export default function EntryForm({ onCancel, onSave, onDelete, onEditElement, accounts, types, state: formProps }: EntryFormProps) {
    return (<Dialog open={formProps.open} onClose={() => { }}>
        <DialogTitle>{formProps.isCreation ? "Aggiungi Spesa" : "Modifica Spesa"}</DialogTitle>
        <DialogContent>
            <Stack spacing={3}>
                <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                    <InputLabel id="account-select-label">Conto</InputLabel>
                    <Select
                        labelId="account-select-label"
                        id="account-select"
                        value={formProps.editElement?.accountId}
                        label="Conto"
                        onChange={(nv) => {
                            onEditElement({
                                ...formProps.editElement!,
                                accountId: (nv.target.value as number) || 0,
                            });
                        }}
                        autoFocus
                    >
                        {accounts.map((a) => {
                            return <MenuItem value={a.id}>{a.name}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Data"
                            value={formProps.editElement?.referenceDate}
                            onChange={(nv) => {
                                if (nv) {
                                    try {
                                        onEditElement({
                                            ...formProps.editElement!,
                                            referenceDate: new Date(nv)
                                                .toISOString()
                                                .substring(0, 10),
                                        });
                                    } catch {
                                        //ignored
                                    }
                                }
                            }}
                            renderInput={(params) => <TextField
                                sx={{ mt: 1 }}
                                size="small" {...params} />}
                        />
                    </LocalizationProvider>
                </FormControl>
                <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                    <InputLabel id="type-select-label">Tipo di Spesa</InputLabel>
                    <Select
                        labelId="type-select-label"
                        id="type-select"
                        value={formProps.editElement?.typeId}
                        label="Tipo di Spesa"
                        onChange={(nv) => {
                            onEditElement({
                                ...formProps.editElement!,
                                typeId: (nv.target.value as number) || 0,
                            });
                        }}
                    >
                        {types.map((a) => {
                            return <MenuItem sx={{ ml: a.level! * 3 }} value={a.id}>
                                {a.name}
                            </MenuItem>
                        })}
                    </Select>
                </FormControl>
                <TextField
                    margin="dense"
                    id="notes"
                    label="Note"
                    type="text"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={formProps.editElement?.notes}
                    onChange={(nv) => {
                        onEditElement({
                            ...formProps.editElement!,
                            notes: nv.target.value,
                        });
                    }}
                />
                <TextField
                    margin="dense"
                    id="amount"
                    label="Valore"
                    inputProps={{
                        inputMode: 'numeric'
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>
                    }}
                    type='text'
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={formProps.editElement?.amountStr}
                    onChange={(nv) => {
                        const newval = nv.target.value.replace(',', '.');
                        const newAmt = Number.parseFloat(newval);

                        onEditElement({
                            ...formProps.editElement!,
                            amount: newAmt,
                            amountStr: newval
                        });
                    }}
                />
            </Stack>
        </DialogContent>
        <DialogActions>
            {formProps.isCreation
                ? <></>
                : <LoadingButton loading={formProps.isPosting} color="error" onClick={onDelete}><Delete /></LoadingButton>}
            <div style={{ flex: '1 0 0' }} />
            <Button onClick={onCancel}>Annulla</Button>
            <LoadingButton
                loading={formProps.isPosting}
                onClick={onSave}>{formProps.isCreation ? "Aggiungi" : "Modifica"}</LoadingButton>
        </DialogActions>
    </Dialog>);
}